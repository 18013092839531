<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 text-left pointer"
							@click="sortByField('order_id')">
							{{ translate('order_id') }}
							<sort field="order_id" />
						</th>
						<th
							class="border-top-0 text-left pointer"
							@click="sortByField('related_order_id')">
							{{ translate('related_order_id') }}
							<sort field="related_order_id" />
						</th>
						<th
							class="border-top-0 text-left pointer">
							<!-- @click="sortByField('increment_id')"> -->
							{{ translate('invoice_id') }}
							<!-- <sort field="increment_id" /> -->
						</th>
						<th
							class="border-top-0 text-left pointer"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<th class="border-top-0 text-center">
							{{ translate('country') }}
						</th>
						<th class="border-top-0 text-left">
							{{ translate('ship_to') }}
						</th>
						<th class="border-top-0 text-left">
							{{ translate('ship_address') }}
						</th>
						<th class="border-top-0 text-left">
							{{ translate('postcode') }}
						</th>
						<th class="border-top-0 text-left">
							{{ translate('payment_method') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('subtotal') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('discount') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('points_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_tax') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('us_taxes') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('duties') }} ({{ translate('non_us') }})
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('v_club_reactivation_fee') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('rural_surcharge') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_collected') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax_collected') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_tax_collected') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_tax_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('fulfillment_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('commission_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total') }}
						</th>
						<th class="border-top-0 text-right">
							{{ translate('amount_received') }}
						</th>
						<th class="border-top-0 text-left">
							{{ translate('transaction_id') }}
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('order_date') }}
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('order_paid_date') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td>
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="text-left">
							{{ item.id }}
							<i
								v-if="item.attributes.is_fraud"
								v-b-tooltip.hover
								:title="translate('fraudulent_order')"
								class="fa fa-info-circle"
								style="margin-left:5px; margin-top:2px; color:#f86c6b" />
							<i
								v-if="item.attributes.is_invisible"
								v-b-tooltip.hover
								:title="translate('invisible_order')"
								class="fa fa-eye-slash"
								style="margin-left:5px; margin-top:2px; color:gray" />
						</td>
						<td class="text-left">
							{{ item.attributes.related_order_id }}
						</td>
						<td class="text-left">
							{{ item.attributes.invoice_id }}
						</td>
						<td class="text-left">
							{{ translate(item.attributes.status) }}
						</td>
						<td class="text-center">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country_code.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country_code.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country_code.toLowerCase())">
						</td>
						<td class="text-left">
							{{ item.attributes.shipping_name }}
						</td>
						<td class="text-left">
							{{ item.attributes.street }}
						</td>
						<td class="text-left">
							{{ item.attributes.postcode }}
						</td>
						<td class="text-left">
							{{ translate(`sales_${item.attributes.payment_method}`) }}
							<span v-if="item.attributes.payment_method === 'free'"> - {{ translate(item.attributes.order_type) }}</span>
						</td>
						<td class="text-right">
							{{ item.attributes.subtotal }}
						</td>
						<td class="text-right">
							{{ item.attributes.discount }}
						</td>
						<td class="text-right">
							{{ item.attributes.points_amount }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_tax }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax_us }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax_no_us }}
						</td>
						<td class="text-right">
							{{ item.attributes.v_club_reactivation_fee }}
						</td>
						<td class="text-right">
							{{ item.attributes.rural_surcharge }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_collected }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax_collected }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_tax_collected }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_tax_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.fulfillment_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.commission_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.total }}
						</td>
						<td class="text-right">
							<a
								v-if="item.attributes.payment_method === 'banktransfer'"
								:href="addressPage('btc', item.attributes.wallet_address)"
								target="_blank">
								<span class="d-inline-block text-truncate w-100">
									{{ item.attributes.amount_received }}
								</span>
							</a>
						</td>
						<td class="text-left cell-custom-width">
							<span
								class="d-inline-block text-truncate w-100">
								{{ item.attributes.transaction_id }}
							</span>
						</td>
						<td class="text-left">
							{{ $moment(item.attributes.created_at.date).format(dateFormat) }}
						</td>
						<td class="text-left">
							{{ $moment(item.attributes.paid_at.date).format(dateFormat) }}
						</td>
					</tr>
					<tr class="text-right">
						<td colspan="10">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.subtotal }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.discount }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.mw_rwrdpoints_cur_amnt }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax_us }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax_no_us }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.v_club_reactivation_fee }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.rural_surcharge }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_collected }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax_collected }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_tax_collected }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_tax_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.fulfillment_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.commission_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
						<td colspan="4">
							<b />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { BLOCKCHAIN_PAGES } from '@/settings/Blockchain';
import {
	MDY_FORMAT as mdyFormat, YMDHMS_FORMAT,
} from '@/settings/Dates';
import {
	Report, Grids, SalesPaymentMethods, Countries, OrderTypes,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';

export default {
	name: 'OrdersInformation',
	messages: [Report, Grids, SalesPaymentMethods, Countries, OrderTypes],
	mixins: [FiltersParams],
	data() {
		return {
			sales: new Sales(),
			dateFormat: YMDHMS_FORMAT,
		};
	},
	computed: {
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		pagination() {
			return this.sales.data.pagination;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';
		this.sales.getSalesOrdersInformation().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.$router.push({ name: this.$route.name, query: options });
		},
		addressPage(cryptocurrency, address) {
			return BLOCKCHAIN_PAGES.address(cryptocurrency, address);
		},
	},
};
</script>

<style scoped>
.cell-custom-width {
	min-width: 240px;
	max-width: 240px;
}
</style>
